var render = function render(){var _vm=this,_c=_vm._self._c;return (
    _vm.docType === 'AccessGuide' ||
    _vm.docType === 'AccessGuideDetail' ||
    _vm.docType === 'AccessGuidePreview' ||
    _vm.docType === 'AccessGuideDetailPreview'
  )?_c('AccessGuideMenu'):(
    _vm.docType === 'ApiDoc' ||
    _vm.docType === 'ApiDocDetail' ||
    _vm.docType === 'ApiDocPreview' ||
    _vm.docType === 'ApiDocDetailPreview'
  )?_c('ApiMenu'):(
    _vm.docType === 'MessageDoc' ||
    _vm.docType === 'MessageDocDetail' ||
    _vm.docType === 'MessageDocPreview' ||
    _vm.docType === 'MessageDocDetailPreview'
  )?_c('MessageMenu'):(
    _vm.docType === 'CommonDevelopmentTools' ||
    _vm.docType === 'CommonDevelopmentToolsDetail'
  )?_c('CommonDevelopmentToolsMenu',{attrs:{"isShrink":_vm.isShrink}}):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }