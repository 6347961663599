<template>
  <!-- 接入指南左侧菜单 -->
  <div
    class="access-guide-menu"
    :class="{ 'access-guide-menu-preview': isRoutePath }"
  >
    <el-menu
      v-for="(item, index) in menuList"
      :key="index"
      :default-active="defaultActive"
      class="access-guide-menu__menu"
      @select="selectMenu"
    >
      <el-submenu
        v-if="item.children"
        :key="item.id.toString()"
        :index="item.id.toString()"
        class="menu__outer-submenu"
      >
        <template slot="title">
          <span slot="title" class="outer-submenu__title">{{
            item.title
          }}</span>
        </template>
        <SubMenu :menuList="item.children" />
      </el-submenu>
      <el-menu-item
        v-else
        :key="item.id.toString()"
        :index="item.id.toString()"
        class="menu__outer-menu-item"
      >
        <span slot="title" class="outer-menu-item__title">{{
          item.title
        }}</span>
      </el-menu-item>
    </el-menu>
  </div>
</template>

<script>
import SubMenu from "@/views/extranet/openBusinessDocuments/components/SubMenu.vue";
import request from "@/utils/request";
import { SappModule } from "@/store";
import isInternational from "@/utils/isInternational";

export default {
  name: "AccessGuideMenu",
  components: {
    SubMenu,
  },
  data() {
    return {
      menuList: [],
      docId: "",
    };
  },
  computed: {
    defaultActive() {
      // console.log('this.$route.params --->', this.$route.params)
      if (this.$route.params.docId) {
        return this.$route.params.docId.toString();
      }
    },
    isRoutePath() {
      return this.$route.path.split("/")[1] === "preview-docking-plan";
    },
    unitId() {
      return this.$route.params.unitId;
    },
    approvalNumber() {
      return SappModule.approvalNumber;
    },
  },
  methods: {
    /**
     * 选择菜单路由
     */
    selectMenu(index) {
      let replacePath = "AccessGuideDetail";
      let query = {};
      if (this.isRoutePath) {
        replacePath = "AccessGuideDetailPreview";
        query = {
          approvalNumber: SappModule.approvalNumber,
        };
      }
      this.$router
        .replace({
          name: replacePath, //"AccessGuideDetail",
          params: { docId: index },
          query,
        })
        .catch((error) => {
          const { message } = error;
          if (
            message.indexOf(
              "Avoided redundant navigation to current location"
            ) === -1
          ) {
            throw new Error(error);
          }
        }).then(() => {
          
        });
    },
    // 初始化默认激活菜单项
    initDefaultActiveMenuItem(currentMenuList) {
      return currentMenuList.some((currentMenuItem) => {
        const { id, children } = currentMenuItem;
        if (children && children.length > 0) {
          return this.initDefaultActiveMenuItem(children);
        } else {
          this.docId = id.toString();
          return true;
        }
      });
    },
    handleEnTitle(list) {
      const locales = this.$i18n && this.$i18n.locale
      const isEnglish = isInternational() && locales && locales === 'en-us'
      if (isEnglish) {
        list.map((item) => {
          item.title = item.titleEn ? item.titleEn : item.title
          if (item.children && Array.isArray(item.children) && item.children.length > 0) {
            this.handleEnTitle(item.children)
          }
        })
      }
      return list
    },
    /**
     * 获取菜单list信息
     */
    async getMenuList(url) {
      let params = {
        objId: this.unitId,
      };
      if (this.isRoutePath) {
        params = {
          id: this.unitId,
          approvalNumber: this.approvalNumber,
        };
      }
      return await request({
        url,
        method: "get",
        params,
      }).catch((e) => {
        console.log(e);
      });
    },
    handleMenuData() {
      let url = "/doc/getDocByGroupByTree";
      if (this.isRoutePath) {
        url = "/bizview/getDocPreByGroupByTree";
      }
      this.getMenuList(url).then(({data}) => {
        this.menuList = this.handleEnTitle(data) || [];
        this.initDefaultActiveMenuItem(this.menuList);
        // 查看当前ID是否可用
        const docId = this.getDefaultDocId()
        // console.log('docId- >', docId)
        this.docId = docId
        if (this.$route.name === "AccessGuide") {
          this.$router.replace({
            name: "AccessGuideDetail",
            params: { docId },
          });
        }
        if (this.$route.name === "AccessGuidePreview") {
          this.$router.replace({
            name: "AccessGuideDetailPreview",
            params: { docId },
            query: {
              approvalNumber: SappModule.approvalNumber,
            },
          });
        }
        // 强制nextTick做重新渲染
        this.$nextTick(() => {
          this.selectMenu(-1)
          this.selectMenu(docId)
        })
      }).catch((error)=>{
        console.log(error)
      });
    },
    // 获取默认跳转的DocId
    getDefaultDocId() {
      const tempDocId = this.defaultActive;
      // console.log('tempDocId->', tempDocId)
      const dp = (menuItem) => {
        for (const item of menuItem) {
          if (item.id == tempDocId) {
            return item.id;
          } else if (item.children && item.children.length > 0) {
            const result = dp(item.children);
            if (result) {
              return result;
            }
          }
        }
      };
      // console.log('this.menuList', this.menuList)
      const id = this.getFirstLeafId(this.menuList)
      if(tempDocId) {
        return dp(this.menuList) || id;
      } else {
        return id
      }
    },
    // 获取存在数据的第一个子叶
    getFirstLeafId(menuList) {
      const findFirstLeaf = (menuItem) => {
        for (const item of menuItem) {
          if (!item.children || item.children.length === 0) {
            return item.id;
          } else {
            const result = findFirstLeaf(item.children);
            if (result) {
              return result;
            }
          }
        }
      };
      return findFirstLeaf(menuList);
    }
  },
  mounted() {
    this.handleMenuData();
  },
  // mounted() {
  //   // 前置渲染等待DOM加载完成
  //   this.selectMenu(this.docId)
  // },
};
</script>

<style lang="scss" scoped>
.access-guide-menu {
  position: sticky;
  top: 120px;
  height: calc(100vh - 120px);
  overflow: auto;

  .access-guide-menu__menu {
    border-right: none;

    ::v-deep .menu__outer-submenu {
      .el-submenu__title {
        background-color: #f6f7fc;

        .outer-submenu__title {
          font-weight: 600;
        }
      }

      &.is-active {
        > .el-submenu__title {
          color: #3c6ef0;
        }
      }
    }

    .menu__outer-menu-item {
      background-color: #f6f7fc;

      .outer-menu-item__title {
        font-weight: 600;
      }
    }
  }
}
.access-guide-menu-preview {
  top: 60px;
}
</style>